<template>
  <ValidationObserver ref="observer" slim>
    <modal-card
      :is-processing="!!processing['payment']"
      :title="
        $t('_action.pay_object', {
          object: $_.lowerFirst(
            $t(invoice.proforma ? '_.proforma_invoice_#' : '_.invoice_#', {
              invoiceNum: invoice.number
            }).toString()
          )
        })
      "
    >
      <template v-if="showPendingPayment">
        <u-message icon="exclamation-circle" type="is-warning">
          <i18n path="_sentence.invoice.due_with_pending_payments_msg" />
          <template #action>
            <u-link
              icon-right="arrow-right"
              @click="showPendingPayment = !showPendingPayment"
            >
              <i18n path="_action.make_additional_payment" tag="strong" />
            </u-link>
          </template>
        </u-message>
      </template>
      <template v-else>
        <is-loading :if="isLoading">
          <has-request-error
            :has-error="hasRequestError"
            :is-loading="isReloading"
            @retry="reloadData()"
          >
            <div>
              <box class="is-relative has-background-light">
                <i18n tag="p" path="_.payment_amount" />
                <p :style="{ lineHeight: '1.15' }">
                  <strong
                    class="is-size-1 has-text-weight-semibold"
                    :style="{ letterSpacing: '-2px' }"
                    >{{ computedPayload.amount_formatted }}</strong
                  >
                  <span
                    v-show="showCurrencyCode"
                    class="is-size-5 has-text-grey-light has-margin-left-25"
                    >{{ computedPayload.currency_code }}</span
                  >
                </p>
                <template v-if="actions.length">
                  <p class="is-size-7 has-text-grey">
                    <template v-for="({ label, method }, pI) of actions">
                      <u-link :key="`$action_${pI}`" @click="method">{{
                        label
                      }}</u-link
                      ><template v-if="pI < actions.length - 1">{{
                        ` • `
                      }}</template>
                    </template>
                  </p>
                </template>

                <b-loading :active="isProcessing" :is-full-page="false" />
              </box>
              <select-payment-method
                :key="computedPayload.currency_code"
                v-model="paymentMethod"
                v-bind="selectPaymentMethodBindings"
                @input="$emit('payment-method-changed', $event)"
              >
                <i18n slot="payInFullLabel" path="_action.pay_object">
                  <template #object>{{
                    computedPayload.amount_formatted
                  }}</template>
                </i18n>
              </select-payment-method>
            </div>
          </has-request-error>
        </is-loading>
      </template>
      <template #footer>
        <b-button
          type="is-success"
          :loading="processing['payment']"
          :disabled="
            isProcessing ||
            hasErrors ||
            isAsynchronousGateway ||
            !hasValidPaymentMethod
          "
          @click="pay"
        >
          <i18n path="_action.pay" />
        </b-button>
      </template>
    </modal-card>
  </ValidationObserver>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import _ from "@/boot/lodash";
import BrandMixin from "@/mixins/brandMixin";
import LoggedUserMixin from "@/mixins/loggedUserMixin";
import PaymentProvider from "@/components/app/global/payments/paymentProvider.vue";
import HasValidation from "@/mixins/HasValidation";
import { DataModules } from "@/store/modules/data/modules";
import { QUERY_PARAMS, BrandConfigKeys } from "@/data/constants";
import { GatewayContext } from "@/data/enums/gateway";
import { ModalCancelByAll } from "@/data/enums";
import type { PropType } from "vue";
import type { Location as VueLocation } from "vue-router";
import type { IInvoice } from "@/models/invoices";
import type { IPaymentDetail } from "@/models/paymentDetails";
import type { ICurrency } from "@upmind-automation/types";
import type { IQuickAction } from "@/models/actions";
import type { IClient } from "@upmind-automation/types";

export default defineComponent({
  name: "InvoicePaymentModal",
  components: {
    "select-payment-method": () =>
      import("@/components/app/global/payments/selectPaymentMethodComp.vue")
  },
  mixins: [PaymentProvider, LoggedUserMixin, HasValidation, BrandMixin],
  props: {
    invoice: { type: Object as PropType<IInvoice>, required: true },
    preselectedStoredPaymentMethodId: {
      type: String as PropType<IPaymentDetail["id"]>,
      default: ""
    },
    preselectedShowPendingPayment: { type: Boolean, default: false },
    externalAuthLocationFunc: {
      type: Function as PropType<() => VueLocation>,
      default: undefined
    },
    preferredCurrencyCode: {
      type: String as PropType<ICurrency["code"]>,
      default: undefined
    }
  },
  data: () => ({
    currencyCode: "",
    currencyId: "",
    hasRequestError: false,
    isLoading: true,
    isReloading: false,
    newAmount: 0,
    newAmountFormatted: "",
    processing: {},
    showPendingPayment: false,
    unpaidAmount: 0,
    unpaidAmountFormatted: ""
  }),
  computed: {
    actions(): IQuickAction[] {
      return _.filter(
        [
          {
            label: this.$t("_action.change_currency"),
            method: () => this.initChangeCurrency(),
            if: this.canChangeCurrency
          },
          {
            label: this.$t("_action.change_amount"),
            method: () => this.initChangeAmount(),
            if: this.canChangeAmount
          },
          {
            label: this.$t("_action.pay_in_full"),
            method: () => this.payInFull(),
            if: this.computedPayload.amount !== this.unpaidAmount
          }
        ],
        action => action.if
      );
    },
    attemptKey() {
      return btoa(
        JSON.stringify({
          invoiceId: this.invoice.id,
          timestamp: this.$moment().toDate().getTime()
        })
      );
    },
    canChangeCurrency(): boolean {
      if (this.hasPartialPayment) return false;
      return (
        this.$store.getters["brand/config"][
          BrandConfigKeys.BILLING_DIFFERENT_CURRENCY_PAYMENT_ENABLED
        ] ?? false
      );
    },
    canChangeAmount(): boolean {
      return (
        this.$store.getters["brand/config"][
          BrandConfigKeys.PARTIAL_PAYMENTS_ENABLED
        ] || this.isAdmin
      );
    },
    computedPayload() {
      const useNew = this.newAmount > 0 && this.newAmount <= this.unpaidAmount;
      return {
        amount: useNew ? this.newAmount : this.unpaidAmount,
        amount_formatted: useNew
          ? this.newAmountFormatted
          : this.unpaidAmountFormatted,
        currency_code: this.currencyCode,
        currency_id: _.find(this.$store.state?.constants?.currencies, [
          "code",
          this.currencyCode
        ])?.id as ICurrency["id"]
      };
    },
    showCurrencyCode() {
      const { amount_formatted, currency_code } = this.computedPayload;
      if (amount_formatted.includes(currency_code)) return false;
      return true;
    },
    hasPendingPayment() {
      return !!this.invoice?.payments?.find(i => i.pending);
    },
    hasPartialPayment() {
      return !!this.invoice?.unpaid_amount && !!this.invoice?.paid_amount;
    },
    isProcessing() {
      return !!Object.keys(this.processing)?.length;
    },
    selectPaymentMethodBindings() {
      const payer = this.invoice?.client as IClient | undefined;
      return {
        accountId: this.invoice.account_id,
        autoSelectFirstStoredPaymentMethod:
          !this.preselectedStoredPaymentMethodId,
        brandId: this.invoice.brand_id,
        canChangeManualPaymentAmount: false,
        clientId: this.invoice.client_id,
        currencyCode: this.computedPayload.currency_code,
        currencyId: this.computedPayload.currency_id,
        externalAuthReturnLocation: this.externalAuthReturnLocation,
        gatewayContext: GatewayContext.PAY,
        hasManualPayment: this.isAdmin,
        hasPartialPayment: false, // Pass false as this component handles capture of partial amount
        hasPayLater: false, // Not applicable in pay invoice context
        hidePaymentTypeSelector: this.isClient,
        invoiceCurrencyId: this.invoice.currency_id,
        isDisabled: this.isProcessing,
        onlineGatewaysOnly: false,
        payerEmail: payer?.email,
        payerFirstname: payer?.firstname,
        payerFullname: payer?.fullname,
        payerLastname: payer?.lastname,
        preselectedStoredPaymentMethodId: this.preselectedStoredPaymentMethodId,
        showManualAmountInput: false,
        totalAmount: this.computedPayload.amount
      };
    },
    additionalPaymentData() {
      const origin = window.location.origin;

      const returnRoute = this.$router.resolve(
        this.externalAuthReturnLocation
      ).route;

      // Construct return URL & set 'attempt' param
      const returnUrl = new URL(returnRoute.fullPath, origin);
      returnUrl.searchParams.set(QUERY_PARAMS.ATTEMPT, this.attemptKey);

      // Set Success Location
      returnUrl.searchParams.set(QUERY_PARAMS.PAYMENT_SUCCESS, "true");
      const successUrlEncoded = encodeURIComponent(returnUrl.toString());

      // Set Failure Location
      returnUrl.searchParams.set(QUERY_PARAMS.PAYMENT_SUCCESS, "false");
      const failureUrlEncoded = encodeURIComponent(returnUrl.toString());

      return {
        client_id: this.invoice.client_id,
        account_id: this.invoice.account_id,
        invoice_id: this.invoice.id,
        return_url: `?${QUERY_PARAMS.SUCCESS}=${successUrlEncoded}&${QUERY_PARAMS.FAILED}=${failureUrlEncoded}`,
        cancel_url: `${origin}${returnRoute.fullPath}`,
        currency_code: this.computedPayload.currency_code
      };
    },
    additionalManualPaymentData() {
      return {
        invoice_id: this.invoice.id,
        currency_code: this.computedPayload.currency_code
      };
    },
    externalAuthReturnLocation(): VueLocation {
      if (_.isFunction(this.externalAuthLocationFunc))
        return this.externalAuthLocationFunc();
      return this.buildResolver({
        returnLocation: this.$store.getters[
          `data/${DataModules.INVOICES}/getRoute`
        ](this.invoice.id, this.invoice.client_id),
        paymentMethodType: this.paymentMethodType,
        initPay: this.isExternalStore
          ? { invoiceId: this.invoice.id }
          : undefined
      }).location;
    },
    totalAmountToPay() {
      return this.invoice.unpaid_amount_converted;
    }
  },
  watch: {
    paymentMethod: {
      handler: "onPaymentMethodChanged",
      immediate: true,
      deep: true
    }
  },
  async created() {
    this.initDataDefaults();
    this.loadData();
    this.showPendingPayment =
      this.preselectedShowPendingPayment || this.hasPendingPayment;
  },
  methods: {
    initDataDefaults() {
      // Here we use 'payment_currency' as priority
      this.currencyCode =
        this.invoice?.payment_currency?.code ||
        this.preferredCurrencyCode ||
        this.invoice?.currency?.code;
      this.currencyId =
        this.invoice?.payment_currency_id || this.invoice?.currency_id;
      this.unpaidAmount = this.invoice?.unpaid_amount_converted;
      this.unpaidAmountFormatted = this.invoice?.unpaid_amount_formatted;
    },
    async loadData() {
      try {
        this.hasRequestError = false;
        await Promise.all([
          // Get brand config settings (in not cached already)
          this.getBrandSettings(),
          // Apply starting currency (could be locked or preferred currency)
          this.applyNewCurrency(this.currencyCode)
        ]);
      } catch (error) {
        this.$store.dispatch("api/handleError", error);
        this.hasRequestError = true;
      } finally {
        this.isLoading = false;
        this.isReloading = false;
      }
    },
    reloadData() {
      this.isReloading = true;
      this.loadData();
    },
    getBrandSettings() {
      return this.$store.dispatch("brand/getConfig", {
        brandId: this.invoice?.brand_id,
        keys: [
          BrandConfigKeys.BILLING_DIFFERENT_CURRENCY_PAYMENT_ENABLED,
          BrandConfigKeys.PARTIAL_PAYMENTS_ENABLED
        ]
      });
    },
    async pay() {
      this.$set(this.processing, "payment", true);
      try {
        if (!(await this.validate())) throw this.$t("_.form_invalid");
        // Prepare payment data
        await this.preparePaymentData(this.invoice.client_id);

        if (this.externalAuth)
          await this.redirectToExternalAuth(this.externalAuth);

        // Execute payment (may redirect off-site for SCA)
        await this.executePayment(this.attemptKey);
        // Emit 'success'
        this.$emit("success", this.invoice);
      } catch (error) {
        this.$emit("fail", this.invoice);
        this.$delete(this.processing, "payment");
        this.$store.dispatch("api/handleValidationError", {
          error,
          vm: this
        });
      }
    },
    async initChangeAmount() {
      if (!this.canChangeAmount) return;
      try {
        const newAmount = await this.captureNewAmount();
        if (!newAmount) return; // Abort if falsey
        this.$set(this.processing, "amount", true);
        const { total, total_formatted } = await this.$store.dispatch(
          "api/calculate",
          {
            prices: [newAmount],
            currency_code: this.currencyCode
          }
        );
        this.newAmount = total;
        this.newAmountFormatted = total_formatted;
      } catch (error) {
        this.$store.dispatch("api/handleError", error);
      } finally {
        this.$delete(this.processing, "amount");
      }
    },
    async captureNewAmount(): Promise<number | null> {
      return new Promise(resolve => {
        this.$store.dispatch("ui/open/windowModal", {
          config: {
            component: () =>
              import("@/components/app/global/payments/changeAmountModal.vue"),
            props: {
              amount: this.computedPayload.amount,
              maxAmount: this.unpaidAmount,
              maxAmountFormatted: this.unpaidAmountFormatted,
              currencyCode: this.computedPayload.currency_code
            },
            width: 440,
            canCancel: ModalCancelByAll,
            onCancel: () => resolve(null),
            events: {
              success: resolve
            }
          }
        });
      });
    },
    payInFull() {
      this.newAmount = 0;
      this.newAmountFormatted = "";
    },
    /**
     * @name initChangeCurrency
     * @desc Here we initialise the process of changing currency
     */
    async initChangeCurrency() {
      if (!this.canChangeCurrency) return;
      const currencyCode = await this.captureNewCurrency();
      if (currencyCode) this.applyNewCurrency(currencyCode);
    },
    /**
     * @name captureNewCurrency
     * @desc Here we capture a new brand currency through a modal flow
     */
    async captureNewCurrency(): Promise<ICurrency["code"] | null> {
      return new Promise(resolve => {
        this.$store.dispatch("ui/open/windowModal", {
          config: {
            component: () =>
              import(
                "@/components/app/global/payments/changeCurrencyModal.vue"
              ),
            props: {
              brandId: this.invoice?.brand_id,
              currencyCode: this.currencyCode
            },
            width: 440,
            canCancel: ModalCancelByAll,
            onCancel: () => resolve(null),
            events: {
              success: resolve
            }
          }
        });
      });
    },
    /**
     * @name applyNewCurrency
     * @desc Here we accept a currency code and attempt to convert the unpaid
     * invoice balance using the new currency.
     */
    async applyNewCurrency(code?: ICurrency["code"]) {
      if (!code) return;
      try {
        this.$set(this.processing, "currency", true);
        const { unpaid_amount, unpaid_amount_formatted } =
          await this.getNewCurrencyAmount(code);
        // Reset partial amounts
        this.newAmount = 0;
        this.newAmountFormatted = "";
        // Set new unpaid amounts
        this.unpaidAmount = unpaid_amount;
        this.unpaidAmountFormatted = unpaid_amount_formatted;
        // Set new currency code
        this.currencyCode = code;
        // Clear previously select payment method
        this.paymentMethod = null;
      } catch (error) {
        this.$store.dispatch("api/handleError", error);
      } finally {
        this.$delete(this.processing, "currency");
      }
    },
    /**
     * @name getNewCurrencyAmount
     * @desc Here we get the unpaid invoice amount, converted for a given brand currency
     */
    async getNewCurrencyAmount(currency_code) {
      return this.$store.dispatch(
        `data/${DataModules.INVOICES}/getUnpaidConvertedAmount`,
        {
          invoiceId: this.invoice.id,
          params: { currency_code },
          vm: this,
          storeData: false,
          returnData: true
        }
      );
    },
    onPaymentMethodChanged() {
      if (!_.isEmpty(this.paymentMethod)) {
        this.$emit("payment-method-selected", this.paymentMethod);
      }
    }
  }
});
</script>
