import Vue from "vue";
import _ from "@/boot/lodash";
import i18n from "@/i18n";
import type { ActionTree, MutationTree, GetterTree } from "vuex";
import type { AxiosError } from "axios";
import Axios from "axios";
import { BrandTaxType, UUID } from "@/data/enums";
import type { IBrand, IBrandSettings } from "@upmind-automation/types";
import type { IImage } from "@/store/types";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import { BrandConfigKeys } from "@/data/constants";
import { StoreDisplayMode } from "@/data/enums/brand";
import { ContrastColor } from "@/data/enums/ui";

export interface IBrandState extends IBrandSettings {
  config: { [Key in BrandConfigKeys]?: string | boolean | number };
}

const initialState = () => {
  return {
    code: "",
    config: {},
    country_id: "",
    currencies: [],
    currency_id: "",
    demo_data_import_id: null,
    domain: "",
    favicon: undefined, // Important favicon is undefined initially
    id: "",
    image: {} as IImage,
    language_id: "",
    languages: [],
    name: "",
    oauth_clients: [],
    prefix: "",
    pricelist_id: "",
    style: {
      brand_color: "#018ffd",
      brand_font: undefined
    },
    tax_type: BrandTaxType.EXCLUDE_TAX,
    vat_exempt: 0,
    vat_number: "",
    wipe_data: false
  } as IBrandState;
};

function getContrastColor(hex = "") {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  if (hex.length === 3) {
    // Shortform HEX
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    // Invalid HEX
    return ContrastColor.WHITE;
  }
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  return r * 0.299 + g * 0.587 + b * 0.114 > 186
    ? ContrastColor.BLACK
    : ContrastColor.WHITE;
}

const stateGetters: GetterTree<IBrandState, IState> = {
  id: state => {
    return _.get(state, "id", null);
  },
  isMultibrand: state => {
    return state.id === UUID.ORG;
  },
  isValidOAuthClient: (state, g, rS, rootGetters) => {
    const { customer_enabled, staff_enabled } =
      state.oauth_clients?.find(
        i => i.origin.toLowerCase() === location.hostname.toLowerCase()
      ) ?? {};
    if (rootGetters.isAdminContext)
      return rootGetters["auth/admin/isAuthenticated"] || staff_enabled;
    return customer_enabled;
  },
  name: state => {
    const name = _.get(state, "name");
    return _.isEmpty(name) ? i18n.t("_.multi_brand") : name;
  },
  config: state => _.get(state, "config", {}),
  color: state => {
    return _.get(state, "style.brand_color", initialState().style?.brand_color);
  },
  contrastColor: (state, getters) => {
    return getContrastColor(getters.color);
  },
  contrastColorHex: (state, getters) => {
    const colors = {
      [ContrastColor.BLACK]: "#151E29",
      [ContrastColor.WHITE]: "#FFFFFF"
    };
    return _.get(colors, getters.contrastColor);
  },
  fontFamily: state => {
    return _.get(
      state,
      "style.brand_font.family",
      initialState().style?.brand_font?.family || "Quicksand"
    );
  },
  country_id: state => {
    return _.get(state, "country_id", "");
  },
  languagesNumber: state => {
    return _.get(state, "languages", []).length;
  },
  currency_id: state => {
    return _.get(state, "currency_id", "");
  },
  currenciesIds: state => {
    return _.map(_.get(state, "currencies", []), currency => currency.id);
  },
  currencies: state => {
    return _.get(state, "currencies", []);
  },
  supportedCurrencies: state => {
    return _.get(state, "currencies", []);
  },
  logo: state => {
    return _.get(state, "image.image_url", null);
  },
  favicon: ({ favicon }) => {
    return _.isNull(favicon) ? null : favicon?.full_url;
  },
  showPricesExcTax:
    state =>
    (taxType: BrandTaxType = state.tax_type): boolean => {
      return taxType === BrandTaxType.EXCLUDE_TAX;
    },
  showStore: ({ config }, g, rS, rootGetters) => {
    switch (
      config?.[BrandConfigKeys.SHOW_CLIENT_STORE] ??
      StoreDisplayMode.SHOW
    ) {
      case StoreDisplayMode.HIDE:
        return false;
      case StoreDisplayMode.SHOW_LOGGED_IN:
        if (rootGetters.isAdminContext) return true;
        return (
          rootGetters["auth/client/isAuthenticated"] ||
          rootGetters["auth/client/isGuestCustomerAuthenticated"]
        );
      case StoreDisplayMode.SHOW:
      default:
        return true;
    }
  },
  hasRegistrationEnabled: ({ config }) => {
    return !(config?.[BrandConfigKeys.DISABLE_CLIENT_REGISTRATION] ?? false);
  },
  taxAwarePrice:
    (s, getters) =>
    (params: {
      taxType?: BrandTaxType;
      taxIncPrice: string;
      taxExcPrice: string;
    }): string => {
      const { taxType, taxIncPrice, taxExcPrice } = params;
      const showPricesExcTax = getters["showPricesExcTax"](taxType);
      const taxAwarePrice = showPricesExcTax ? taxExcPrice : taxIncPrice;
      const isFree = taxAwarePrice.match(/^\D*(0[\\.\\,]00)\D*$/);
      return isFree && isFree.length
        ? (i18n.t("_.free") as string)
        : taxAwarePrice;
    },
  taxSummary:
    (s, getters) =>
    ({
      taxType,
      taxAmount,
      taxIncPrice,
      taxExcPrice
    }: {
      taxType?: BrandTaxType;
      taxAmount: number;
      taxPercentage: string;
      taxIncPrice: string;
      taxExcPrice: string;
    }): string => {
      const showPricesExcTax = getters["showPricesExcTax"](taxType);
      // If +ve tax amount AND prices shown EXCL. tax
      if (!!taxAmount && showPricesExcTax)
        // Show inclusive price summary
        return i18n.t("_.price_including_tax", {
          price: taxIncPrice
        }) as string;
      // If +ve tax amount AND prices shown INCL. tax
      else if (!!taxAmount && !showPricesExcTax)
        // Show exclusive price summary
        return i18n.t("_.price_excluding_tax", {
          price: taxExcPrice
        }) as string;
      else return "";
    }
};

const actions: ActionTree<IBrandState, IState> = {
  get: async (
    { state, commit, dispatch, rootGetters },
    { ignoreStored = false } = {}
  ) => {
    if (!ignoreStored && !!state.id) return;
    // Reset any cached ticket departments
    await dispatch("constants/binDepartments", null, { root: true });
    try {
      // Get brand settings + default config values
      const [settings, config] = await Promise.all([
        dispatch(
          "api/call",
          {
            method: Methods.GET,
            path: rootGetters.isAuthenticatedAdminContext
              ? "api/admin/brand/settings"
              : "api/brand/settings",
            callConfig: {
              /** Here we make sure to only pass an access token when in an
               * authenticated admin context, otherwise the app's boot sequence
               * can break for users with an expired access token */
              withAccessToken: rootGetters.isAuthenticatedAdminContext
            }
          },
          { root: true }
        ),
        /* Here we retrieve core brand config values (such as the default
        app landing page). We silently catch any errors as we don't want to
        block the app from loading if an unexpected error is encountered */
        dispatch("getConfig", {
          ignoreStored,
          keys: [
            BrandConfigKeys.ANALYTICS_GA_MEASUREMENT_ID,
            BrandConfigKeys.ANALYTICS_GTM_CONTAINER_ID,
            BrandConfigKeys.BASKET_DEFAULT_CURRENCY,
            BrandConfigKeys.BASKET_PAYMENT_TERM_DESCRIPTIONS,
            BrandConfigKeys.BILLING_DIFFERENT_CURRENCY_PAYMENT_ENABLED,
            BrandConfigKeys.BILLING_GATEWAY_FORCE_AUTO_PAYMENT,
            BrandConfigKeys.BILLING_GATEWAY_FORCE_CARD_STORAGE,
            BrandConfigKeys.CHECKOUT_FLOW,
            BrandConfigKeys.CHECKOUT_HIDE_DISCOUNT_CODE_FIELD,
            BrandConfigKeys.CHECKOUT_SUMMARY_COLOR_STOP1,
            BrandConfigKeys.CHECKOUT_SUMMARY_COLOR_STOP2,
            BrandConfigKeys.CHECKOUT_SUMMARY_CONTRAST_MODE,
            BrandConfigKeys.CLIENT_NOTES_AND_SECRETS_ENABLED,
            BrandConfigKeys.CLIENT_TICKET_SCHEDULING_ENABLED,
            BrandConfigKeys.DEFAULT_CLIENT_HOMEPAGE,
            BrandConfigKeys.DISABLE_CLIENT_REGISTRATION,
            BrandConfigKeys.INVOICE_CONSOLIDATION_ENABLED,
            BrandConfigKeys.INVOICE_CONSOLIDATION_RESTRICT_TO_STAFF,
            BrandConfigKeys.PARTIAL_PAYMENTS_ENABLED,
            BrandConfigKeys.PREVENT_CARD_REMOVAL_IF_LAST,
            BrandConfigKeys.REQUIRE_PHONE_ON_REGISTRATION,
            BrandConfigKeys.SHOP_TRUNCATE_DESCRIPTIONS,
            BrandConfigKeys.SHOW_CLIENT_STORE,
            BrandConfigKeys.SUPPORT_PIN_ENABLED,
            BrandConfigKeys.UI_CLIENT_APP_DISABLE_SUPPORT_SYSTEM,
            BrandConfigKeys.UI_CLIENT_APP_PAGE_AFTER_LOGIN,
            BrandConfigKeys.UI_ENTER_KEY_ACTION,
            BrandConfigKeys.UI_PRICE_BEFORE_DISCOUNT_POSITION,
            BrandConfigKeys.UI_LOGO_URL
          ]
        }).catch(err => err)
      ]);
      // Commit brand and config
      commit("brand", {
        ...settings.data,
        config,
        style: {
          brand_color:
            settings.data?.style?.brand_color ||
            initialState().style?.brand_color,
          brand_font:
            settings.data?.style?.brand_font || initialState().style?.brand_font
        }
      });
    } catch (error) {
      const code = (error as AxiosError).response?.status ?? 400;
      if (code === 404) {
        // 404 Error
        window.location.href = import.meta.env
          .VITE_APP_UPMIND_STOREFRONT as string;
      } else if (code === 503) {
        // 503 (Scheduled Maintenance)
        dispatch("ui/open503ErrorModal", null, { root: true });
      } else if (code >= 500) {
        // 500 (Error)
        dispatch("ui/open5XXErrorModal", null, { root: true });
      } else if (await dispatch("api/isNetworkError", error, { root: true })) {
        // Network Error
        dispatch("ui/openNetworkErrorModal", null, { root: true });
      } else {
        commit("brand", { ...initialState(), id: UUID.ORG });
      }
    }
  },
  getConfig: async (
    { commit, dispatch, state, rootGetters },
    {
      keys,
      ignoreStored = false,
      brandId = UUID.ORG
    }: { keys: number[]; ignoreStored: boolean; brandId: IBrand["id"] }
  ) => {
    if (!ignoreStored) {
      let i = keys.length;
      while (i--) {
        const key = keys[i];
        if (_.has(state.config, key)) {
          keys.splice(i, 1);
        }
      }
    }
    if (!keys.length) return state.config;
    const brandFilter =
      !!brandId && brandId !== UUID.ORG ? { brand_id: brandId } : {};

    const response = await dispatch(
      "api/call",
      {
        method: Methods.GET,
        path:
          rootGetters.isAuthenticatedAdminContext &&
          !rootGetters.isMockClientContext()
            ? "api/admin/config/brand/values"
            : "api/config/brand/values",
        callConfig: {
          /** Here we make sure to only pass an access token when in an
           * authenticated admin context, otherwise the app's boot sequence
           * can break for users with an expired access token */
          withAccessToken: rootGetters.isAuthenticatedAdminContext
        },
        requestConfig: {
          params: {
            ...brandFilter,
            keys: keys.join(",")
          }
        }
      },
      { root: true }
    );
    const config = response.data;
    commit("setConfig", config);
    return config;
  },
  getGoogleFonts: async () => {
    const res = await Axios.get(
      "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCU7rmkRctIkoaQzr_AD_VNY5ceGi4fZlU"
    );
    return res.data?.items || [];
  },
  openSelectFontModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/settings/brands/fonts/selectFontModal.vue"
            ),
          width: 720,
          ...payload
        }
      },
      { root: true }
    );
  }
};

const mutations: MutationTree<IBrandState> = {
  brand: (state, brand: IBrandState) => {
    Object.assign(state, brand);
  },
  id: (state, id: IBrandState["id"]) => {
    Vue.set(state, "id", id);
  },
  setConfig: (state, config) => {
    Vue.set(state, "config", { ...state.config, ...config });
  },
  setImage: (state, payload: { image: IImage }) => {
    state.image = payload.image;
  },
  reset: state => {
    Object.assign(state, initialState());
  }
};

export default {
  namespaced: true,
  state: initialState(),
  getters: stateGetters,
  actions,
  mutations
};
